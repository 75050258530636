import React from "react";
import "./styles/labeler.scss";
import {ApiEngine} from "api-engine";
import LabelCard from "./models/LabelCard";
import AddLabelForm from "./components/labels/AddLabelForm";
import {LabelCardsViewMode} from "./components/labels/enums/LabelCardsViewMode";
import PhotoDisplayClass from "./components/photo_displays/PhotoDisplayClass";
import LabelsList from "./components/labels/LabelsList";
import User from "../../../../../../../../../../common/models/User";

interface LabelerProps {
  api: ApiEngine
  project: any
  dataSource: any
  dataSourceFile: any
  dataLoading: boolean
  user: User
}

interface LabelerState {
  labels: any[]
  imageWidth: number | null
  imageHeight: number | null,
  currentRectangleCoordinates: number[],
  allRectangleCoordinates: any[]
  blockActions: boolean
  error: any
  viewMode: LabelCardsViewMode
  hoveredTitle: string | null
  dataLoading: boolean,
  allLabelCards: any[]
}

function labelsSortingFunc(_a: any, _b: any): number {
  if (_a.time_frame === _b.time_frame) {
    return 0;
  }
  return _a.time_frame > _b.time_frame ? 1 : -1;
}

export default class ImageLabeler extends React.Component<LabelerProps, LabelerState> {
  mounted = false
  private photoDisplayRef = React.createRef<PhotoDisplayClass>();
  private imageRef = React.createRef<HTMLImageElement>();
  private controlLineRef= React.createRef<HTMLDivElement>();
  private addLabelFormRef = React.createRef<HTMLInputElement>();

  constructor(props: LabelerProps) {
    super(props);

    // const labels = this.props.dataSourceFile.labels.sort(labelsSortingFunc);
    this.state = {
      imageWidth: null,
      imageHeight: null,
      currentRectangleCoordinates: [],
      blockActions: false,
      error: null,
      viewMode: LabelCardsViewMode.ALL,
      hoveredTitle: null,
      dataLoading: this.props.dataLoading,
      labels: [],
      allRectangleCoordinates: [],
      allLabelCards: []
    }
    this.handleRectangleSelect = this.handleRectangleSelect.bind(this);
    this.handleRectangleEdit = this.handleRectangleEdit.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.photoDisplayResizeCallbackOnMount = this.photoDisplayResizeCallbackOnMount.bind(this);
    this.addLabelCard = this.addLabelCard.bind(this);
    this.deleteLabelCard = this.deleteLabelCard.bind(this);
    this.handleSaveLabel = this.handleSaveLabel.bind(this);
    this.convertLabelToLabelCard = this.convertLabelToLabelCard.bind(this);
    this.convertLabelToCoordinates = this.convertLabelToCoordinates.bind(this);
    this.labelsAsLabelCards = this.labelsAsLabelCards.bind(this);
    this.labelsAsRectangleCoordinates = this.labelsAsRectangleCoordinates.bind(this);
    this.onHover = this.onHover.bind(this);
    this.restoreWidthAndHeight = this.restoreWidthAndHeight.bind(this);
    this.downloadLabels = this.downloadLabels.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;

    this.mounted = true;
    this.restoreWidthAndHeight();
  }

  downloadLabels(width: number, height: number): Promise<boolean> {
    const me = this;

    return new Promise((_resolve) => {
      const labelsUrl = `/api/projects/data_sources/data_source_files/${me.props.dataSourceFile.id}/labels/index`;
      me.props.api.asyncFetch(labelsUrl, {}).then((_res) => {
        if (!_res.labels) return;
        me.setState({
          labels: _res.labels,
          allLabelCards: me.labelsAsLabelCards(_res.labels, width, height),
          allRectangleCoordinates: me.labelsAsRectangleCoordinates(_res.labels, width, height)}, () => {
            _resolve(true)
        })
      })
    })

  }

  restoreWidthAndHeight() {
    const me = this;
    if (!this.imageRef.current) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return
    }
    if (!this.imageRef.current) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return;
    }
    // alert(`${this.imageRef.current.getBoundingClientRect().width} x ${this.imageRef.current.getBoundingClientRect().height}`)
    const width =  this.imageRef.current.getBoundingClientRect().width;
    const height =  this.imageRef.current.getBoundingClientRect().height;
    if (!width || !height) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return;
    }
    // console.log(width, height)
    this.setState({
      imageWidth: width,
      imageHeight: height,
    }, () => {
      setTimeout(() => {
        me.downloadLabels(width, height).then(() => {
          me.handleRectangleSelect(0,0,0,0,0)
        })
      }, 0);
    });
  }

  convertLabelToLabelCard(_label: any, width: number, height: number) {
    return {
      id: _label.id,
      title: _label.title,
      time_frame: 0,
      coordinates:
        [ _label.x0,
          _label.y0,
          _label.x1,
          _label.y1,
          0
        ]
    }
  }

  convertLabelToCoordinates(_label: any, width: number, height: number) {
    // const res = [_label.x0 * width, _label.y0 * height, _label.x1 * width , _label.y1 * height, _label.time_frame ];
    const res = [_label.x0, _label.y0, _label.x1, _label.y1, 0, _label.title ];
    return res;
  }

  photoDisplayResizeCallbackOnMount(width: number, height: number) {
    const me = this;
    me.setState({
      imageWidth: width,
      imageHeight: height,
    });
  }

  handleRectangleSelect(startX: number, startY: number, endX: number, endY: number, timeFrame?: number) {
    const me = this;
    this.setState({
      currentRectangleCoordinates: [startX, startY, endX, endY, timeFrame!],
    }, () => {
    });
  };

  addLabelCard(newLabelCard: LabelCard, rectangleCoordinates: number[]): Promise<void> {
    const me = this;

    return new Promise((resolve, reject) => {
        if (!me.state.imageWidth || !me.state.imageHeight) {
            alert("Не заданы параметры изображения")
            reject();
            return;
        }
        newLabelCard.coordinates = rectangleCoordinates;
        const url = `/api/projects/data_sources/data_source_files/${me.props.dataSourceFile.id}/labels/new`;
        // alert(`${width} x ${height}`)
        let dataToSend = {
            title: newLabelCard.title,
            project: {id: me.props.project.id},
            dataSource: {id: me.props.dataSource.id},
            dataSourceFile: {id: me.props.dataSourceFile.id},
            x0: rectangleCoordinates[0],
            y0: rectangleCoordinates[1],
            x1: rectangleCoordinates[2],
            y1: rectangleCoordinates[3],
            timeFrame: 0
        };
        me.props.api.asyncFetch(url, {method: "POST", body: JSON.stringify(dataToSend)}).then((_res) => {
            // document.location.reload();
            const newLabels = [...me.state.labels, _res].sort(labelsSortingFunc);
            me.setState({
                currentRectangleCoordinates: [],
                labels: newLabels,
                allLabelCards: me.labelsAsLabelCards(newLabels, me.state.imageWidth!!, me.state.imageHeight!!),
                allRectangleCoordinates: me.labelsAsRectangleCoordinates(newLabels, me.state.imageWidth!!, me.state.imageHeight!!)
            }, () => {
                resolve();
            });
        });

    });
  }

  labelsAsLabelCards(labels: any[], width: number, height: number) : LabelCard[] {
    const me = this;
    const res =  labels.map((_x: any) => {
      return me.convertLabelToLabelCard(_x, width, height);
    })
    // console.log(res);
    return res;
  }

  labelsAsRectangleCoordinates(labels: any[], width: number, height: number) : number[][] {
    const me = this;
    console.log(me.state.imageWidth)
    return labels.map((_label: any) => {
      return me.convertLabelToCoordinates(_label, width, height);
    });
  }

  deleteLabelCard(card: any) {
    const me = this;
    const url = `/api/projects/data_sources/data_source_files/${me.props.dataSourceFile.id}/labels/${card.id}/delete`;
    me.props.api.asyncFetchWithoutQueing(url,{}).then((_res) => {
      if (!_res) {
        alert("Не удалось удалить");
        return;
      }
      const myIndex = me.state.labels.map((x) => {
        return x.id
      }).indexOf(card.id);
      let newLabels = [...me.state.labels];
      newLabels.splice(myIndex, 1);
      me.setState({
        labels: newLabels,
        allLabelCards: me.labelsAsLabelCards(newLabels, me.state.imageWidth!!, me.state.imageHeight!!),
        allRectangleCoordinates: me.labelsAsRectangleCoordinates(newLabels, me.state.imageWidth!!, me.state.imageHeight!!)
      });
    })
  };

  handleRectangleEdit(index: number) {
    const me = this;
  };

  handleSaveLabel(editedLabelCard: LabelCard, editedCoordinates: number[]) {
  }

  handleCancelEdit() {
  };

  onHover(_title: string) {
    if (_title === this.state.hoveredTitle) {
      console.log(`Not changing hover, because equal`);
      return;
    }
    this.setState({
      hoveredTitle: _title
    });
  }

  render() {
    const me = this;
    let imageUrl = me.props.dataSourceFile.file_url;
    if (!imageUrl) return <p>Загрузка данных</p>
    imageUrl = imageUrl.indexOf("http") > -1 ? imageUrl.replace(/([^:]\/)\/+/g, "$1") : `${me.props.api.serverUrl}/${imageUrl.replace(/([^:]\/)\/+/g, "$1")}`;
    return <div className={"flex flex-column"}>
      { me.props.dataSourceFile.ai_description &&
          <>
            <p style={{maxWidth: "900px"}}>🤖&nbsp;{me.props.dataSourceFile.ai_description}</p>
            <br/>
          </>
      }
      <div className={"labeler"}>
      <div className="left-column-labeler">
        {/*<p>{me.state.labels.length}/${me.state.allRectangleCoordinates.length}/${me.state.allLabelCards.length}</p>*/}
        <div style={{position: "relative"}}>
          <PhotoDisplayClass
              ref={this.photoDisplayRef}
              width={me.state.imageWidth}
              height={me.state.imageHeight}
              duration={0}
              changeCurrentTime={(_newTime: number) => {
              }}
              key={`${me.state.imageWidth}-${me.state.imageHeight}-${me.state.hoveredTitle}-${JSON.stringify(me.state.labels)}`}
              labels={me.state.allLabelCards}
              hoveredLabelTitle={me.state.hoveredTitle}
              initialCurrentTime={0}
              callback={() => {
              }}
              imageUrl={imageUrl}
              onRectangleSelect={me.handleRectangleSelect}
              allRectangleCoordinates={me.state.allRectangleCoordinates}
              setAllRectangleCoordinates={(_allRectangleCoordinates: any) => {
              }
              }
          />

          <img
              alt={"Размечаемое изображение"}
              ref={this.imageRef}
              src={imageUrl}
              style={{
                zIndex: 0,
                width: window.innerWidth < 700 ? "100%" : "57vw",
                height: window.innerWidth < 700 ? "auto" : "auto",
                pointerEvents: "none"
              }}
          />

        </div>
      </div>
      <div className="right-column"
           key={JSON.stringify(me.state.labels) + `${me.state.imageWidth}-${me.state.imageHeight}`}>
        <h5>Размеченные данные</h5>

        {imageUrl && me.state.imageHeight && me.state.imageWidth && (
            <AddLabelForm
                ref={me.addLabelFormRef}
                active={me.state.currentRectangleCoordinates.length > 0}
                project={me.props.project}
                dataSource={me.props.dataSource}
                dataSourceFile={me.props.dataSourceFile}
                labels={me.state.labels}
                key={`${me.state.imageWidth}-${me.state.imageHeight}`}
                addLabelCard={me.addLabelCard}
                currentRectangleCoordinates={me.state.currentRectangleCoordinates}
            />
        )}
        {me.state.dataLoading ? <p>Разметка загружается</p> :
            <>
              <LabelsList api={me.props.api}
                          key={JSON.stringify(me.state.labels)}
                          labels={me.state.labels}
                          deleteLabelCard={me.deleteLabelCard}
                          rectangleCoordinates={me.state.allRectangleCoordinates}
                          addLabelCard={me.addLabelCard}
                          onHover={me.onHover}
                          setTime={() => {
                          }}
              />

              {me.state.error &&
                  <p style={{color: "var(--red)"}}>{me.state.error}</p>
              }
              {me.state.labels.length > 0 && me.props.user.canTrainLearningModels &&
                  <div className={"flex flex-row justify-between mt-10 content-center items-center"}
                       style={me.state.blockActions ? {opacity: 0.4, pointerEvents: "none"} : {}}
                  >
                    <button onClick={() => {
                      me.setState({
                        blockActions: true
                      }, () => {
                        me.props.api.asyncFetchBlobWithoutQueing(`/api/projects/data_sources/${me.props.dataSource.id}/download_dataset.zip`, {
                          method: "GET",
                          headers: {
                            "Accept": "application/zip"
                          }
                        }).then((blob: any) => {
                          const url = window.URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.style.display = 'none';
                          a.href = url;
                          // the filename you want
                          a.download = `dataset_${me.props.dataSource.id}.zip`;
                          document.body.appendChild(a);
                          a.click();
                          window.URL.revokeObjectURL(url);
                          me.setState({blockActions: false});
                        }, (_err) => {
                          me.setState({blockActions: false, error: _err});
                        });
                      });
                    }}>Датасет&nbsp;&nbsp;<i className={"fal fa-download"}/></button>
                  </div>
              }
            </>
        }
      </div>
    </div>
    </div>
  }
}